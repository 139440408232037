<template>
  <v-container>
    <div
      style="
        height: calc(30vh);
        position: fixed;
        top: calc(40vh);
        left: 0;
        width: 100%;
      "
    >
      <v-row>
        <v-col> </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          <h1
            class="font-weight-bold"
            style="font-size: 30px; font-size: 3.5vw; letter-spacing: 3px"
          >
            TS Fizio- és Mozgástér
          </h1>
          Jelentkezzen be!
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({}),
};
</script>
