<template>
  <v-app class="bg">
    <v-app-bar app color="rgba(0,0,0,0.58)" dark dense elevation="3">
      <v-img
        max-height="40"
        max-width="40"
        src="./assets/logo.png"
        contain
        class="mr-5"
        style="margin-left: -10px"
        @click="
          if ($route.path != '/dashboard') {
            $router.push({ name: 'dashboard' });
          }
        "
      ></v-img>
      <span
        @click="
          if ($route.path != '/dashboard') {
            $router.push({ name: 'dashboard' });
          }
        "
      >
        TS Fizio- és Mozgástér
      </span>
      <v-spacer></v-spacer>
      <!-- <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="item in aktiv_menu"
          :key="item.title"
          :to="item.link"
          @click="menuActions(item.title)"
          text
          small
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items> -->

      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
        </template>
        <v-list dark color="rgba(0,0,0,0.3)">
          <v-list-item
            v-for="item in aktiv_menu"
            :key="item.title"
            :to="item.link"
            @click="menuActions(item.title)"
          >
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$store.state.loggedIn"
      expand-on-hover
      fixed
      dark
      floating
      permanent
      style="margin-top: 37pt"
      color="rgba(0,0,0,0.3)"
      :value="true"
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              :src="
                'https://ts.laryon.hu/images/photos/' +
                $store.state.userid +
                '.jpg'
              "
            ></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1">
              {{ $store.state.nev }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-group xvalue="true" no-action prepend-icon="mdi-database">
          <template v-slot:activator>
            <v-list-item link>Törzsadatok</v-list-item>
          </template>

          <v-list-item link to="/felhasznalok">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Felhasználók</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-human-queue</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Partnerek</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-alpha-t-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Termékek</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-cart-arrow-down</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Beszerzések</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-cart-arrow-up</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Értékesítés</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="bg">
      <v-container fluid class="gray" style="padding-left: 70px">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-dialog
      v-model="jelszocsereDialog"
      persistent
      max-width="600px"
      min-width="360px"
    >
      <div>
        <v-card>
          <v-toolbar color="brown darken-1" dark>
            Jelszócsere<v-spacer> </v-spacer>
            <v-icon @click="jelszocsereDialog = false"
              >mdi-close</v-icon
            ></v-toolbar
          >
          <v-card-text class="pt-6">
            <v-form ref="jelszocsereForm" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="jelenlegiJelszo"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Jelenlegi jelszó"
                    counter
                    @click:append="show1 = !show1"
                    @keyup.enter="validate"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ujJelszo1"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Új jelszó"
                    counter
                    @click:append="show2 = !show2"
                    @keyup.enter="validate"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ujJelszo2"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-3"
                    label="Új jelszó újra"
                    counter
                    @click:append="show3 = !show3"
                    @keyup.enter="validate"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" xsm="12"
                  >{{ errorMessage }}
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                  <v-btn
                    large
                    block
                    :disabled="!valid"
                    color="success"
                    @click="validate"
                  >
                    Ment
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  computed: {
    aktiv_menu() {
      let vm = this;
      return this.menu.filter(function (item) {
        return (
          (!item.auth || vm.$store.state.loggedIn) &&
          !(vm.$store.state.loggedIn && item.nologgedin)
        );
      });
    },
  },

  data() {
    return {
      jelszocsereDialog: false,
      ujJelszo1: "",
      ujJelszo2: "",
      jelenlegiJelszo: "",
      valid: false,
      show1: false,
      show2: false,
      show3: false,
      rules: {
        required: (value) => !!value || "Kötelező kitölteni!",
      },
      errorMessage: "",
      menu: [
        {
          auth: false,
          nologgedin: true,
          title: "Bejelentkezés",
          link: "login",
        },

        {
          auth: true,
          title: "Jelszócsere",
        },
        {
          auth: true,
          title: "Kijelentkezés",
          link: "/",
        },
      ],
    };
  },
  methods: {
    validate() {
      let ok = true;
      if (this.ujJelszo1 != this.ujJelszo2) {
        this.errorMessage = "Nem egyeznek az új jelszavak!";
        ok = false;
      }

      if (!this.jelenlegiJelszo || !this.ujJelszo1 || !this.ujJelszo2) {
        this.errorMessage = "Minden mezőt ki kell tölteni!!";
        ok = false;
      }
      if (ok) {
        let vm = this;
        let formData = new FormData();
        formData.append("jelenlegiJelszo", this.jelenlegiJelszo);
        formData.append("ujJelszo", this.ujJelszo1);
        formData.append("user_rn", this.$store.state.userid);
        formData.append("action", "jelszocsere");
        axios
          .post("https://ts.laryon.hu/backend/data.php", formData)
          .then(function (response) {
            if (response.data.error) {
              vm.errorMessage = response.data.error;
            } else {
              vm.jelszocsereDialog = false;
              vm.$refs.jelszocsereForm.reset();
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    toggle() {
      this.$store.state.toggle = !this.$store.state.toggle;
    },
    jelszocsere() {
      this.jelszocsereDialog = true;
    },
    kijelentkezes() {
      this.$store.state.loggedIn = false;
      let token = localStorage.getItem("token");

      let vm = this;
      let formData = new FormData();
      formData.append("token", token);
      formData.append("action", "logout");
      axios
        .post("https://ts.laryon.hu/backend/login.php", formData)
        .then(function () {
          vm.$store.state.userid = "";
          //vm.$router.push({ name: "login" });
          localStorage.setItem("token", "");
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    menuActions(title) {
      if (title == "Kijelentkezés") {
        this.kijelentkezes();
      }
      if (title == "Jelszócsere") {
        this.jelszocsere();
      }
    },
  },
};
</script>

<style>
.bg {
  background-image: url("assets/tsfizio.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.gray {
  background-color: rgba(5, 5, 5, 0.6);
  min-height: calc(100vh - 48px);
  color: white;
}
</style>