<template>
  <div>
    <!--  <v-row>
      <v-col cols="3">
        <v-text-field
          solo
          dense
          placeholder="Keresés névre, email címre"
          v-model="search"
        ></v-text-field>
      </v-col>
      <v-col
        cols="3"
        v-for="jog in jogosultsag"
        :key="jog.value"
        class="text-center"
      >
        {{ jog.text }}: {{ letszam(jog.value) }}
      </v-col>
    </v-row> -->
    <div class="d-flex justify-space-around flex-wrap">
      <v-card
        v-for="felhasznalo in szurtFelhasznalok"
        :key="felhasznalo.recno"
        class="ma-2 white--text"
        width="400"
        color="rgba(0,0,0,0.3)"
        style="backdrop-filter: blur(7px)"
        hover
        outlined
      >
        <v-card-title>
          {{ felhasznalo.nev }}
        </v-card-title>
        <v-card-text class="white--text">
          <v-row>
            <v-col cols="8">
              <v-row dense>
                <v-col cols="12">{{ felhasznalo.email }}</v-col>
                <v-col cols="12">{{ felhasznalo.telefon }}</v-col>
                <v-col cols="12">{{ jogNev(felhasznalo.jogok) }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="4" align="end" class="fill-height">
              <v-avatar size="100%">
                <img :src="kep(felhasznalo.recno)" />
              </v-avatar>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row dense>
              <v-col cols="9">
                <v-icon
                  @click="modiFelhasznalo(felhasznalo)"
                  v-if="vanJoga('admin')"
                  color="orange"
                >
                  mdi-account-edit-outline
                </v-icon>
                <v-icon
                  @click="deleteFelhasznalo(felhasznalo)"
                  class="ml-3"
                  v-if="vanJoga('admin')"
                  color="orange"
                >
                  mdi-delete
                </v-icon>
              </v-col>
              <v-col cols="3">
                <div
                  style="position: absolute; bottom: 18px; right: 10px"
                  v-if="
                    vanJoga('admin') || felhasznalo.recno == $store.state.userid
                  "
                >
                  <v-file-input
                    prepend-icon="mdi-camera orange--text "
                    hide-input
                    hide-details
                    dense
                    @change="foto(felhasznalo.recno)"
                    accept="image/png, image/jpeg, image/bmp"
                    v-model="photos"
                  >
                  </v-file-input>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-card>
      <v-icon
        v-if="vanJoga('admin')"
        style="position: fixed; bottom: 10px; right: 10px"
        color="brown darken-1"
        size="40px"
        @click="newFelhasznalo()"
      >
        mdi-account-plus-outline
      </v-icon>

      <v-dialog v-model="felhasznaloDialog" max-width="600">
        <v-card>
          <v-toolbar color="brown darken-1" dark
            >{{ formTitle }}<v-spacer></v-spacer
            ><v-icon
              @click="
                felhasznaloDialog = false;
                formData = {};
              "
              >mdi-close</v-icon
            ></v-toolbar
          >
          <v-card-text>
            <v-form v-model="felhasznaloForm" class="mt-6">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    regular
                    label="Név"
                    :rules="[(value) => !!value || 'Kötelező kitölteni!']"
                    v-model="formData.nev"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    regular
                    label="E-mail"
                    :rules="[
                      (v) =>
                        /.+@.+\..+/.test(v) || !v || 'Helyes e-mail címet írj!',
                    ]"
                    v-model="formData.email"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    regular
                    label="Telefon"
                    v-model="formData.telefon"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    dense
                    :items="jogosultsag"
                    label="Jogosultsági szint"
                    :rules="[(value) => !!value || 'Kötelező kitölteni!']"
                    v-model="formData.jogok"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" v-if="formData.recno">
                  <v-text-field
                    type="password"
                    dense
                    regular
                    label="Új jelszó (ha nincs kitöltve, a jelszó nem módosul)"
                    v-model="formData.jelszo"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12"></v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-space-between">
            <v-file-input
              class="mb-5 ml-2"
              prepend-icon="mdi-camera"
              hide-input
              :disabled="!formData.recno"
              @change="foto(formData.recno)"
              accept="image/png, image/jpeg, image/bmp"
              v-model="photos"
            >
            </v-file-input>
            <v-btn text @click="mentFelhasznalo()" :disabled="!felhasznaloForm"
              >Ment</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ErrorDialog v-model="errorMessage"></ErrorDialog>
      <Confirm ref="confirm"></Confirm>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { serialize } from "object-to-formdata";
export default {
  components: {},
  name: "Felhasznalok",
  data() {
    return {
      photos: [],
      felhasznalok: [],
      felhasznaloForm: false,
      errorMessage: "",
      felhasznaloDialog: false,
      jogosultsag: [
        { value: "admin", text: "Adminisztrátor" },
        { value: "ugyintezo", text: "Ügyintéző" },
      ],
      felhasznaloFormAction: "",
      formData: {},
      search: "",
    };
  },
  computed: {
    szurtFelhasznalok() {
      let vm = this;

      if (this.search) {
        return this.felhasznalok.filter(function (item) {
          return (
            item.nev.toLowerCase().indexOf(vm.search.toLowerCase()) > -1 ||
            item.email.toLowerCase().indexOf(vm.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.felhasznalok;
      }
    },
    formTitle() {
      return this.felhasznaloFormAction == "new"
        ? "Új felhasználó felvétele"
        : "Felhasználó adatainak módosítása";
    },
  },
  mounted() {
    this.getFelhasznalok();
  },
  methods: {
    foto(user_rn) {
      const form_data = serialize({
        photos: this.photos,
        action: "kep_upload",
        user_rn: user_rn,
      });
      let vm = this;
      axios({
        method: "post",
        url: "https://ts.laryon.hu/backend/data.php",
        data: form_data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.getFelhasznalok();
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    letszam(jog) {
      return this.felhasznalok.filter(function (item) {
        return item.jogok == jog;
      }).length;
    },
    deleteFelhasznalo(felhasznalo) {
      let vm = this;

      let formData = new FormData();

      formData.append("action", "deleteFelhasznalo");
      formData.append("recno", felhasznalo.recno);
      this.$refs.confirm

        .open(
          "Felhasználó törlése",

          "Biztosan törli " + felhasznalo.nev + " felhasználót?"
        )
        .then((confirm) => {
          if (confirm) {
            axios
              .post("https://ts.laryon.hu/backend/data.php", formData)
              .then(function (response) {
                if (response.data.error) {
                  vm.errorMessage = response.data.error;
                } else {
                  vm.errorMessage = "";
                  vm.felhasznalok = response.data;
                }
              })
              .catch(function (error) {
                console.error(error);
              });
          }
        });
    },
    jogNev(jog) {
      let x = this.jogosultsag.find(function (item) {
        return item.value == jog;
      });
      if (x) {
        return x.text;
      }
    },

    kep(recno) {
      return (
        "https://ts.laryon.hu/images/photos/" +
        recno +
        ".jpg" +
        "?" +
        new Date()
      );
    },
    newFelhasznalo() {
      this.felhasznaloFormAction = "new";
      this.felhasznaloDialog = true;
    },
    modiFelhasznalo(felhasznalo) {
      this.formData = {};
      this.formData.nev = felhasznalo.nev;
      this.formData.email = felhasznalo.email;
      this.formData.telefon = felhasznalo.telefon;
      this.formData.jogok = felhasznalo.jogok;
      this.formData.recno = felhasznalo.recno;

      this.felhasznaloFormAction = "modi";
      this.felhasznaloDialog = true;
    },
    mentFelhasznalo() {
      let vm = this;
      let formData = new FormData();

      Object.entries(this.formData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("action", "mentFelhasznalo");

      axios
        .post("https://ts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.felhasznaloDialog = false;
            vm.formData = {};
            vm.felhasznalok = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getFelhasznalok() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getFelhasznalok");
      axios
        .post("https://ts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.felhasznalok = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>

<style>
</style>