<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
      <div>
        <v-card class="px-4">
          <v-card-text class="pt-3">
            <v-form ref="loginForm" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="loginEmail"
                    :rules="loginEmailRules"
                    label="E-mail"
                    required
                    class="mt-10"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="loginPassword"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Jelszó"
                    counter
                    @click:append="show1 = !show1"
                    @keyup.enter="validate"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" xsm="12"
                  >{{ errorMessage }}
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                  <v-btn
                    large
                    block
                    :disabled="!valid"
                    color="success"
                    @click="validate"
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,

      valid: true,

      loginPassword: "",
      loginEmail: "",
      loginEmailRules: [
        (v) => !!v || "Kötelező kitölteni!",
        (v) => /.+@.+\..+/.test(v) || "Helyes e-mail címet írj!",
      ],

      show1: false,
      rules: {
        required: (value) => !!value || "Kötelező kitölteni!",
      },
      errorMessage: "",
    };
  },
  computed: {},
  mounted() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      let token = localStorage.getItem("token");
      let userid = localStorage.getItem("userid");
      let vm = this;
      let formData = new FormData();
      formData.append("token", token);
      formData.append("userid", userid);
      formData.append("action", "checklogin");
      axios
        .post("https://ts.laryon.hu/backend/login.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
            vm.$store.state.loggedIn = false;
          } else {
            vm.errorMessage = "";
            //  console.log(response);
            if (
              response.data.userid == userid &&
              response.data.token == token
            ) {
              vm.$store.state.userid = response.data.userid;
              vm.$store.state.loggedIn = true;
              vm.dialog = false;
              vm.$store.state.jogok = localStorage.getItem("jogok");
              vm.$store.state.nev = localStorage.getItem("nev");
              vm.$store.state.email = localStorage.getItem("email");
              vm.$router.push({ name: "dashboard" });
            } else {
              vm.dialog = true;
              vm.$store.state.userid = "";
              vm.$store.state.loggedIn = false;
              vm.dialog = true;
            }
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    validate() {
      if (this.$refs.loginForm.validate()) {
        this.login();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    login() {
      let vm = this;
      let formData = new FormData();

      formData.append("email", this.loginEmail);
      formData.append("password", this.loginPassword);
      formData.append("action", "login");
      axios
        .post("https://ts.laryon.hu/backend/login.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
            vm.$store.state.loggedIn = false;
          } else {
            vm.errorMessage = "";
            //     console.log(response);

            localStorage.setItem("userid", response.data.userid);
            localStorage.setItem("nev", response.data.nev);
            localStorage.setItem("jogok", response.data.jogok);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("email", vm.loginEmail);
            vm.$store.state.userid = response.data.userid;
            vm.$store.state.loggedIn = true;
            vm.$store.state.jogok = response.data.jogok;
            vm.$store.state.nev = response.data.nev;
            vm.$emit("loggedIn");
            vm.dialog = false;
            vm.$router.push({ name: "dashboard" });
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>