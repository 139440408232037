import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Login from '../components/Login.vue'

import Dashboard from '../components/Dashboard.vue'
import Felhasznalok from '../components/Felhasznalok.vue'

import store from '@/store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },

  {
    path: '/felhasznalok',
    name: 'felhasznalok',
    component: Felhasznalok,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  console.log('router');
  console.log(to);
  console.log(from);

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!store.state.loggedIn) {

      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }

});

export default router
